<template>
    <div>
        <el-main>
            <el-button type="primary" style="margin-bottom: 10px" size="small" @click="toAdd()">+添加分类</el-button>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="分类名称：">
                    <el-input size="small" placeholder="请输入分类名称" v-model="searchForm.classify_name"></el-input>
                </el-form-item>
                <el-form-item label=" " label-width="60px">
                    <el-button type="primary" size="small" @click="getList">搜索</el-button>
                    <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="name" label="分类名称" min-width="120" align="center"></el-table-column>
                <el-table-column prop="update_time" label="更新时间" align="center" min-width="120">
                    <template slot-scope="scope">{{ scope.row.update_time ? getDateformat(scope.row.update_time) :
                        getDateformat(scope.row.create_time) }}</template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="delItem(scope.row.id)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
        <el-dialog :title="(is_edit ? '编辑' : '添加') + '分类'" :visible.sync="dialogVisible" width="40%">
            <el-form ref="form" :model="form" label-width="100px" :rules="rules" label-position="left">
                <el-form-item label="分类名称：" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            key: new Date(),

            searchForm: {
                page: 1,
                rows: 10,
                name: '',
            },
            total_number: 0,
            dialogVisible: false,

            form: {
                name: '',
                sort: 0,
            },
            is_edit: false,
            rules: {
                name: [
                    { required: true, message: '请输入分类名称', trigger: 'blur' },
                ]
            }
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat,
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        cancelSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                name: '',
            };
            this.getList();
        },
        getList () {
            let data = Object.assign({}, this.searchForm);
            this.$axios.post(this.$api.repair.marketing.ActivityCateList, data).then(res => {
                if (res.code == 0) {
                    this.total_number = res.result.total;
                    this.list = res.result.list;
                    this.key = new Date()
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        edit (row) {
            this.form = {
                id: row.id,
                name: row.name,
                sort: row.sort,
            }
            this.dialogVisible = true
            this.is_edit = true
        },
        toAdd () {
            this.form = {
                name: '',
                sort: 0,
            }
            this.dialogVisible = true
            this.is_edit = false
        },
        delItem (id) {
            this.$confirm('确定要删除此数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.post(this.$api.repair.marketing.ActivityCateDel, { id }).then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        },
        confirm () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let url = this.is_edit ? this.$api.repair.marketing.ActivityCateEdit : this.$api.repair.marketing.ActivityCateAdd
                    this.$axios.post(url, this.form).then(res => {
                        if (res.code == 0) {
                            this.$message.success((this.is_edit ? '编辑' : '添加') + '成功');
                            this.dialogVisible = false
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
}

.el-table {
    .userInfo {
        display: flex;
        align-items: center;

        .el-image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
}

.row {
    display: flex;
    align-items: center;

    .icon {
        font-size: 18px;
        margin-left: 10px;
        color: #409eff;
        margin-top: 2px;
    }
}

.el-form-search {
    margin-bottom: 25px;
}

.formBtn {
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.cardUl {
    width: 100%;
    background-color: #fff;
    margin-top: 50px;
    padding: 50px 0;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
        width: 45%;
        display: flex;
        justify-content: space-between;

        span {
            font-size: 14px;
            color: gray;
        }
    }

    .cardLi {
        width: 80%;
        border: 20px solid #bbbbbb;
        position: relative;
        margin-bottom: 50px;

        .cardLiTil {
            background-color: #f0eeee;
            color: gray;
            font-size: 18px;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .cardLiTab {
            padding: 20px;
        }

        .cardTabPage {
            display: flex;
            justify-content: center;

            position: absolute;
            bottom: 20px;
            left: 40%;
        }
    }
}

.el-form-item {
    margin-bottom: 10px;
}

.rejection {
    width: 100%;
    background-color: #fff;
    border: 2px solid #000;

    .rejectionTil {
        padding: 10px 20px;
        border-bottom: 1px solid #ccc;

        span {
            background-color: #f0eeee;
        }
    }

    .rejectionPage {
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

.rejectionTab {
    padding: 20px;

    div {
        margin-bottom: 10px;
        font-size: 14px;
    }
}

.rejectionPage {
    display: flex;
    justify-content: flex-end;
}
</style>
